.custom-select {
    width: 200px;
    padding: 10px;
    margin: 0;
    border-radius: 4px;
    border: 1px solid #ced4da;
    appearance: none;
    background: url("../../assets/svgs/Down_arrow.svg") no-repeat;
    background-position: right .75rem center;
    background-size: .65em auto;
}

.custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}