.no-result-found {
  margin-top: 250px;
  text-align: center;
  margin-bottom: 250px;
}

.search-result-card {
  width: 20%;
  box-sizing: border-box;
  @media only screen and (max-width: 780px) {
    width: 25%;
  }
  @media only screen and (max-width: 570px) {
    width: 50%;
  }
}

.search-title {
  @media only screen and (max-width: 780px) {
    font-size: 30px !important;
    padding-top: 8px !important;
  }
  @media only screen and (max-width: 570px) {
    font-size: 20px !important;
    padding-top: 8px !important;
  }
}
