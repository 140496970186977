@import './variables';

.secondary__button, .primary__button {
  border-radius: 8px;
  width: 368px;
  font-family: "gothic-bold", sans-serif;
  font-size: 20px;
  height: 50px;
  outline: unset !important;
  text-align: center;
  @media only screen and (max-width: 490px) {
    width: 90% !important;
  }
}

.IOS{
  padding-top: 6px;
}

.secondary__button {
  border: 1px solid #ffffff;
  color: #ffffff;


  &:hover {
    color: #79A8D1;
    border-color: #79A8D1;
  }

  &:focus {
    color: #848E9A;
    border-color: #848E9A;
  }

  &:disabled {
    color: #FFFFFF34;
    border-color: #FFFFFF33;
  }
}

.primary__button {
  background-color: #FFFFFF ;
  border: 1px solid #0A233F;
  color: #0A233F;

  &:hover {
    background-color: #79A8D1;
  }

  &:focus {
    background-color: #848E9A;
  }

  &:disabled {
    color: #0A233F80;
    border-color: #FFFFFF33;
  }
}

.primary__button--lg {
  @extend .primary__button;
  width: 100%;
}

.secondary__button--lg {
  @extend .secondary__button;
  width: 100%;
}

.secondary__button--lg-icon {
  @extend .secondary__button;
  width: 100%;
  position: relative;
  color: #0A2240 !important;
  & > :first-child {
    position: absolute;
    width: 22px;
    height: 22px;
    left: 10%;
    top: 26%;
  }
}
