@import '../../../styles/variables';

.manage {
    &-title {
        color: #FFFFFF;
        letter-spacing: -0.92px;
        font-family: $tgd1;
        font-size: 40px;
        text-align: center;
        margin-bottom: 100px;
    }

    &-body {
        // width: 715px;
        height: 289px;
        margin: auto;
    }

    &-device {
        text-align: center;
        font-size: 18px;
        font-family: $tglbc;
        margin-bottom: 51px;
        img{
            height: 25px;
        }
    }

    &-item {
        border-bottom: solid 1px;
        border-bottom-color: #364B61;
        // width: 715px;
        height: 54px;
        position: relative;
        font-size: 21px;
        font-family: $tglc;

        .icon {
            position: absolute;
            top: 21px;
            left: 0;
        }

        p {
            position: absolute;
            left: 35px;
            text-align: center;
            top: 16px;
        }

        .feather-chevron {
            position: absolute;
            top: 21px;
            right: 15px;
        }
        &-empty {
            height: 2px;
            background-color: unset;
        }

        &-buttons {

            background: #0A2240 0% 0% no-repeat padding-box;
            height: 54px;
            margin-bottom: 2px;
            color: #FFFFFF;
            font-family: $tglc;
            font-size: 20px;
            cursor: pointer;

            button {
                background-color: unset;
                border: none;
                color: #FFFFFF;
                font-family: $tglbc;
                text-align: left !important;
                font-size: 20px;
                position: absolute;
                top: 16px;
                left: 12px;
                width: 100%;
                &:focus {
                    outline: none;
                }
            }

            span {
                
                font-family: $tglc;
                font-size: 14px;
                left: 17px;
                top: 38px;
                position: absolute;
            }
        }
    }
}

.help-with-subscription {
    text-align: left;
    font-family: $tglb;
    font-size: 18px;
    margin-top: 50px;

    .link-blue {
        margin-left: 5px;
        margin-top: 11px;
        text-decoration: none;
    }

    .square-plus-button {
        color: #fff;
    }
}