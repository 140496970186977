.icon-input {
  position: relative;
  .form-control {
    padding-right: 40px;
    &.is-invalid {
      & + div {
        display: none;
      }
    }
  }
  svg {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
}
