@import '../../styles/variables';

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .landing_page {
    color: #ffffff;
    font-family: $tglb;
    min-height: 100%;
    position: relative;
    flex: 1;

    #landingPageVideo,
    .landingPageImage {
      object-fit: cover;
      width: 100vw;
      top: 0;
      position: absolute;
      right: 0;
      min-width: 100%;
      height: 100%;
      z-index: -2;
    }

    .landingPageImage {
      width: unset;
      min-width: unset;
      left: 0;
      position: fixed;

      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    .darkMode {
      display: none;

      @media only screen and (max-width: 768px) {
        display: unset;
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        position: fixed;
        right: 0;
        min-width: 100%;
        min-height: 100%;
        z-index: -1;
      }
    }

    .logo-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 50px;

      .yes-logo,
      .gotham-logo {
        max-width: 200px;
        display: block;
        margin: 0 auto;
      }

      .gotham-logo {
        margin-top: 10px;
      }
    }

    .phase2-desc {
      display: flex;
      flex-direction: column;

      .phase2-desc-1 {
        font-size: 24px;
        font-weight: 700;
        // font-family: $tglc;
        margin-top: 20px;

        // .bold-text {
        //   font-weight: 700;
        //   font-family: $tglc;
        // }

        .faq-link {
          color: white;
        }
      }

      .phase2-desc-2 .bold {
        font-weight: 700;
        font-family: $tglbc;
      }

      .phase2-desc-2 .regular {
        font-weight: 400;
        font-family: $tglc;
      }
    }

    .login_section {
      max-width: 768px;
      text-align: center;
      padding-top: 220px;
      padding-bottom: 63px;

      @media only screen and (max-width: 768px) {
        padding-top: 30px;
        padding-bottom: 0px;
      }

      .TV_login_desc {
        letter-spacing: 0.56px;
        font-size: 28px;

        @media only screen and (max-width: 320px) {
          font-size: 22px;
        }
      }

      .subscriber_section_desc {
        font-size: 28px;

        @media only screen and (max-width: 320px) {
          font-size: 22px;
        }
      }

      .subscriber_section_sub_desc {
        letter-spacing: 0.48px;
        font-size: 24px;
        font-family: $tglc;
        padding-bottom: 40px;

        @media only screen and (max-width: 320px) {
          font-size: 16px;
        }
      }

      hr {
        width: 425px;
        opacity: 0.15;
        height: 2px;
        margin: revert;

        @media only screen and (max-width: 490px) {
          width: 90%;
        }
      }

      .secondary__button,
      .primary__button {
        @media only screen and (max-width: 768px) {
          width: 430px;
        }
      }

      .uppercase-text {
        text-transform: uppercase;
      }

      .secondary__button {
        background: $dark-blue-1;
        margin: 16px;

        @media only screen and (max-width: 768px) {
          margin: 10px 0px;
        }

        @media only screen and (max-width: 320px) {
          margin: 10px 0px;
          font-size: 17px;
        }
      }

      .subscribe_section {
        margin-top: 30px;

        @media only screen and (max-width: 768px) {
          margin-bottom: 38px;
        }
      }
    }
  }
}
