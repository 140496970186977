@import '../../../styles/variables';

.btn-done-section {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  background: #364B61 0% 0% no-repeat padding-box;
  padding: 19px 0;
  height: 95px;
  right: 0;
  left: 0;
  font-family: $tglb;
  min-height: 150px;

  button {
    border-radius: 8px;
    border: 1px solid #0A233F;
    width: 50%;
    color: #0A233F;
    background-color: #ffffff;
    height: 45px;

    &:hover {
      background-color: #79A8D1;
    }
  }
}

.btn-done-section-onboarding {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  background: #364B61 0% 0% no-repeat padding-box;
  padding: 15px 0;
  // height: 90px;
  right: 0;
  left: 0;
  font-family: $tglb;
  position: absolute;
  z-index: 9;

  button {
    border-radius: 8px;
    border: 1px solid #0A233F;
    width: 30%;
    color: #0A233F;
    background: #ffffff;
    height: 45px;
    margin: 10px 0px;

    &:hover {
      background-color: #79A8D1;
    }
  }
}

.player-first-name {
  margin-bottom: 0px;
}

.player-last-name {
  margin-bottom: 0px;

}

.team {
  background-color: #fff;
  border-radius: 8px;
  width: 265px;
  height: 40px;
  margin: 10px;
  font: normal normal bold 16px/20px PT Sans;
  letter-spacing: 0.16px;
  color: #0a2240;
  text-transform: uppercase;
  opacity: 1;
  cursor: pointer;

  &:hover {
    background-color: #79A8D1;
  }

  @media only screen and (max-width: 280px) {
    width: 165px;
    margin: 5px;
  }
}

.selected-team {
  background-color: #848E9A;
  border-radius: 8px;
  width: 265px;
  height: 40px;
  margin: 10px;
  font: normal normal bold 16px/20px PT Sans;
  letter-spacing: 0.16px;
  color: #0A2240;
  text-transform: uppercase;
  opacity: 1;
  cursor: pointer;

  @media only screen and (max-width: 280px) {
    width: 165px;
    margin: 5px;
  }
}

.favourite-player-card {
  width: 100%;
  height: 40vh;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
}

.team-logo {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.player-list {
  height: 100%;
  width: 100%;
}

.player-list-select {
  height: fit-content;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
}

.subscribe-button {
  border-radius: 8px;
  border: 1px solid #FFFFFF;
  border-width: thin;
  opacity: 1;
  color: #FFFFFF;
  width: 25%;
  height: 40px;
  font: normal normal bold 14px/18px PT Sans;
  cursor: pointer;
}

.subscribe-button-active {
  background: #848E9A 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border-style: solid;
  border-width: thin;
  opacity: 1;
  color: #fff;
  width: 25%;
  height: 40px;
  font: normal normal bold 14px/18px PT Sans;
  cursor: pointer;
}

.player-item {
  border-bottom: solid 1px;
  border-bottom-color: #364B61;
  padding: 10px;
}

.player-picture {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-color: #364b61;
}

.player-picture-none {
  display: none;
}

.player-name {
  margin: 0px;
  font-family: $tgd1;
  letter-spacing: 0.06px;
  color: #FFFFFF;
  text-transform: uppercase;
}

.player-list-container {
  background-color: rgba(255, 255, 255, 0.06);
  height: 40vh;
  width: 100%;
  margin-top: 20px;
  overflow: auto;
}

.btn-add-more-players {
  opacity: 1;
  color: #ffff;
  font: normal normal bold 18px/35px PT Sans;
  width: 50%;
  border-style: solid;
  border-width: thin;
  text-align: center;
  border-radius: 8px;
  border-color: #ffff;
  cursor: pointer;

  &:hover {
    background-color: #79A8D1;
  }
}

.btn-add-fav-players {
  color: #fff;
  border: 1px solid;
  text-align: center;
  border-radius: 8px;
  margin: 6px;
  cursor: pointer;
  width: 368px;
}

.fav-container {
  height: 100%;
  width: 100%;
  margin-top: 20px;
  padding: 85px;
  text-align: center;
}

.team-name {
  font: normal normal bold 16px/20px PT Sans;
  letter-spacing: 0.16px;
  color: #ffff;
  text-transform: uppercase;
  opacity: 1;
  padding: 10px;
}

.team-section {
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;

}

.onboarding-fav-players {
  // display: flex;
  flex-direction: column;
  margin: auto;
  // background: #F2F3F5;
  // margin-top: -115px;
  // margin-bottom: 50px;
  // border-radius: 8px;
  // padding-top: 10px;
}

.update {
  width: 100%;
  text-align: center;
  height: 40px;
  margin-bottom: 10px;
  background: #364B61 0% 0% no-repeat padding-box;
  padding: 19px 0;
  height: 88px;
  position: absolute;
  right: 0;
  left: 0;
  font-family: $tglb;

  button {
    @media only screen and (max-width: 400px) {
      width: 100%;
    }
  }
}