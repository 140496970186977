@import '../../../styles/variables';

.credit-card-screen {
  justify-content: center;

  @media only screen and (min-height: 870px) and (min-width:768px) {
    margin: 0 20px 193px 20px !important;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 0 20px 248px 20px !important;
  }

  .plans-section {
    max-width: 330px;
    margin: 34px 105px 0 98px;
    padding: unset;

    @media only screen and (max-width: 768px) {
      margin: 34px 0 0 0;
    }

    &-title {
      padding-bottom: 25px;
      font-family: $tglbc;
      font-size: 19px;
    }

    .planImages {
      @media only screen and (max-width: 768px) {
        margin-left: -10px;
      }
    }

    // annual normal price banner 
    .plan-card__annual-offer {
      position: absolute;
      width: 100%;
      padding: 2px;
      height: 25px;
      box-sizing: border-box;
      background-color: #3DB449;
      top: 0;
      left: 0;
      transform-origin: 130px 155px;
      text-align: center;
    }

    .plan-card__annual-offer--selected::after {
      content: url('../../../assets/images/Check_mark_selected.png');
      position: absolute;
      right: 3%;
      top: 13%
    }

    .plan-card__monthly-offer--selected {
      height: 17px;
      background-image: url('../../../assets/images/Check_mark.png');
      background-repeat: no-repeat;
      position: relative;
      left: 90%;
      top: 5%
    }

    // base class
    .annual-price-card {
      padding: 5px;
      width: 345px;
      height: 230px;
      border-radius: 7px;
      border: 3px solid transparent;
      /* Placeholder for border color */
    }

    .annual-price-card--selected {
      border-color: #FFFFFF;
      /* Selected state */
    }

    .annual-price-card:hover {
      border-color: #79A8D1;
      /* Hover state for unselected cards */
    }

    // base class
    .monthly-price-card {
      padding: 5px;
      width: 345px;
      height: 180px;
      border-radius: 7px;
      border: 3px solid transparent;
      /* Placeholder for border color */
    }

    .monthly-price-card--selected {
      border-color: #FFFFFF;
      /* Selected state */
    }

    .monthly-price-card:hover {
      border-color: #79A8D1;
      height: 155px;
      /* Hover state for unselected cards */
    }

    // base class
    .price-card {
      padding: 5px;
      width: 345px;
      height: 227px;
      border-radius: 7px;
      border: 3px solid transparent;
      /* Placeholder for border color */
    }

    .price-card--selected {
      border-color: #FFFFFF;
      /* Selected state */
    }

    .price-card:hover {
      border-color: #79A8D1;
      /* Hover state for unselected cards */
    }

    // base class
    .annual-plan-card {
      width: 330px;
      height: 215px;
      border-radius: 7px;
      background-color: #000000;
      position: relative;
      overflow: hidden;
    }

    .annual-plan-card--selected {
      background-color: #ffffff;
      /* Selected state */
    }

    .monthly-plan-card {
      width: 330px;
      height: 140px;
      border-radius: 7px;
      background-color: #000000;
      position: relative;
      overflow: hidden;
      padding-top: 11px;
    }

    .monthly-plan-card--selected {
      background-color: #ffffff;
      padding-top: 0px;

      /* Selected state */
    }

    .plan-card__price {
      display: flex;
      justify-content: center;
      padding-top: 36px;
      text-align: center;
      font-size: 32px;
      color: #ffffff;
      font-weight: 700;
      font-family: $tglbc;
      line-height: 33.6px;
      margin-left: 40px;
    }

    .plan-card__price--selected {
      color: #0A233F;
    }

    .monthly-plan-card__price {
      display: flex;
      justify-content: center;
      padding-top: 15px;
      text-align: center;
      font-size: 32px;
      color: #ffffff;
      font-weight: 700;
      font-family: $tglbc;
      line-height: 33.6px;
      margin-left: 40px;
    }

    .monthly-plan-card__price--selected {
      color: #0A233F;
    }

    .plan-card__tax-text {
      font-size: 12px;
      color: #ffffff;
      font-family: $tglc;
      text-align: center;
      line-height: 14.4px;
      margin-top: 16px;
      margin-left: 10px;
      font-weight: 400;
    }

    .plan-card__tax-text--selected {
      color: #0A233F;

    }

    .plan-card__per-month__text {
      color: #ffffff;
      align-items: flex-end;
      font-size: 16px;
      margin-top: 2px;
      text-align: center;
      line-height: 19.2px;
      font-family: $tglc
    }

    .plan-card__per-month__text--selected {
      color: #0A233F;
    }

    .price-card-dropdown select {
      width: 289px;
      padding: 10px;
      background-color: #364B61;
      color: #fff;
      border: 1px solid #364B61;
      border-radius: 4px;
      margin-bottom: 12px;
      cursor: pointer;
      text-align: center;
      margin-left: 20px;
    }

    .price-card-installment__text {
      text-align: center;
      margin-top: 7px;
      color: #FFFFFF;
      font-family: $tglc;

    }

    .price-card-installment__text--selected {
      color: #0A233F;
      font-weight: 300;
      font-family: $tglc;
    }


    .plan-card__promo-text {
      color: #ffffff;
      font-size: 16px;
      text-align: center;
      align-items: center;
      line-height: 19.2px;
      margin-left: 7px;
      font-family: $tglc
    }

    .plan-card__promo-text--selected {
      color: #0A233F;

    }

    .promo {
      margin-top: 15px;
    }


    .check-mark-selected {
      display: flex;
      height: 17px;
      margin-top: 5px;
      margin-left: 150px;
      width: 21px;
      justify-content: center;
      align-items: center;
      background-image: url('../../../assets/svgs/check_mark.svg');
    }


    .plans {
      text-align: center;
      margin-left: 20px;
      cursor: pointer;
      display: flex;

      @media only screen and (max-width: 768px) {
        margin-left: 0;
      }

      .monthly-plan,
      .plan-card {
        height: 160px;
        width: 330px;
        min-width: 180px;
        background-color: rgba(0, 0, 0, 0.5);
        margin-bottom: 15px;
        border-radius: 7px;
        color: #FFFFFF;
        position: relative;
        font-family: $tglbc;
        border-style: none;
        outline: unset;

        &:hover {
          border: 3px solid #79A8D1;
          border-radius: 7px;
        }

        &.plansMonthlySelected,
        &.planCardSelected {
          background-color: #FFFFFF;
          height: 165px;
          width: 335px;
          border-radius: 7px;
          border: 3px solid #FFFFFF;
          color: black;
          padding: 5px;
          background-clip: content-box;
          font-family: $tglbc;
          outline: unset;

        }

        .plan-name {
          position: absolute;
          width: 100%;
          top: 12px;
          font-size: 13px;
          font-family: $tgd1;
        }

        .plan-price {
          font-size: 22px;
          font-family: $tglb;
        }

        .plan-plus-tax {
          font-size: 10px;
          margin-top: -4px;
          font-family: $tglbc;
        }

        .plan-description {
          font-size: 12px;
          font-family: $tglbc;
        }

        .plan-save {
          transform: rotate(325deg);
          position: absolute;
          left: 0;
          top: 15px;
          font-family: $tglb;
          font-size: 18px;
          letter-spacing: -0.42px;
          color: #FFFFFF;
        }

        .saving-banner {
          position: absolute;
          left: 0;
          top: 0px;
        }
      }
    }

    li {
      font-size: 14px;
      list-style-type: none;
      margin-top: 7px;
    }

    .check-mark {
      padding-right: 10px;
      margin-left: -26px;
    }

    .secondary-list {
      font-size: 14px;
      font-family: $tglc;
      list-style-type: disc;

      li {
        list-style-type: disc;
        margin-top: 2px;
      }
    }

    .includes {
      font-family: $tglbc;
      font-size: 19px;
      margin-top: 10px;


      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    .change-your-plan {
      text-align: center;

      button {
        width: 100%;
        margin-top: 90px;
        margin-bottom: 30px;
      }

      .link-blue {
        color: #FFFFFF !important;
      }
    }
  }

  .is-update-mode {
    max-width: 720px !important;

    .plan-card {
      margin-right: 10px;
      margin-top: 100px;

      @media only screen and (max-width: 768px) {
        margin-top: 0;
      }

      p {
        margin: 0;
        height: 0;
      }
    }

    .change-your-plan {
      button {
        width: 80%;
      }

      .link-blue {
        text-align: center;
      }
    }
  }

  .center-border {
    max-width: 0;
    height: 571px;
    border: 1px solid #9DA7B2;
    opacity: 0.65;
    margin: 82px 0 0 0;
    padding: unset;

    @media only screen and (max-width: 768px) {
      max-height: 0;
      max-width: 571px;
      margin: unset;
    }

    @media only screen and (max-width: 1368px) {
      display: none;
      max-width: 0px;
    }
  }

  .payment-section {
    max-width: 571px;
    margin: 34px 98px 0 97px;
    padding: unset;

    .col-sm-6 {
      width: 50%;
    }

    .col-sm-3 {
      width: 25%;
    }

    .col-sm-4 {
      width: 33.33333%;
    }

    .col-sm-8 {
      width: 66.66667%;
    }

    @media only screen and (max-width: 768px) {
      margin: 34px 0 0 0;
    }

    .subscribe-button {
      width: 100%;
      padding: 5px;
      text-align: center;
      border: none;

      button {
        width: 100%;
      }
    }

    .subscribe-link {
      text-align: center;
      padding-top: 20px;
      font-family: $tglb;
    }

    .message {
      text-align: left;
      letter-spacing: 0px;
      font-family: $tglc;
      font-size: 13px;
      margin-top: 5px;
    }

    .form-group {
      margin-bottom: 0.5rem !important;
    }

    input.form-control {
      margin-bottom: 25px !important;
    }
  }

  .payment-skip__link {
    color: #79A8D1;
    display: flex;
    justify-content: center;
    margin-left: 25px;
    border: none;
    background: transparent;
    text-decoration: underline;

    @media only screen and (max-width: 768px) {
      margin-left: 0px;
    }
  }

  .payment-skip__div {
    display: flex;
    justify-content: center;
  }
}