@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

//color variable
$dark-blue: #0a2240;
$grey: #74777b;
$light-grey-1: #a2a2b2;
$white: #fff;
$light-grey: #dee2e0;
$dark-red: #a03b32;
$light-red: #f4dedc;
$blue: #6fabd0;
$dark-grey:#84909E;
$light-blue:#333132;
$dark-blue-1:#0A233F;

// font variable
$pts: 'PT Sans',
sans-serif;
$lf: 'Libre Franklin',
sans-serif;
$tgd1: 'gothic-display-1';
$tglbc: 'gothic-bdcn20';
$tglbc1: 'gothic-bdcn18';
$tglb: 'gothic-bold';
$tglc: 'gothic-cn18';

// bootstrap variable customization
$theme-colors: (primary: $dark-blue,
  // $purple is defined in _variables.scss
  default: $white,
  danger: $dark-red,
  dark: $white);
$btn-font-size: 18px;
$input-border-color: #adacac;
$input-border-radius: 7px;
$form-label-color: $dark-blue;
$form-label-font-size: 18px;
$form-label-font-weight: bold;
$form-label-margin-bottom: 0px;
$input-padding-y-lg: 1.2rem;
$input-padding-y: 0.65rem;
$form-feedback-invalid-color: $dark-red;

@import '~bootstrap/scss/bootstrap';