@import '../../../styles/variables';

.manage-account {
  width: 100%;
  padding: 30px 20px 50px 20px;
  height: 50vh;
}

.manage-account-form {
  height: 100%;
}

.gender-dropdown {
  background: url("/drop down.svg") no-repeat right 19px top 50% !important;
  background-size: 17px 9px !important;
  border: 1px solid #FFFFFF4D !important;
  border-radius: 5px !important;
  background-color:  #0a233f !important;
  color: #FFFFFF !important;
}

#root > div.page-background > div> div > div > form > div.row > div > div > label > span.gender-optional {
  color: #FFF;
  font-size: 16px;
}

.register {
  height: 100vh;
  input.form-control {
    color: #FFFFFF;

    &:hover {
      border: 1px solid #FFFFFF
    }

    &:focus {
      border: 1px solid #79A8D1
    }
  }

  .terms-link {
    font-size: 15px;
    font-family: $tglc;
  }

  .submit-register {
    text-align: center;
    background: #364B61 0% 0% no-repeat padding-box;
    padding: 19px 0;
    height: 88px;
    position: absolute;
    right: 0;
    left: 0;
    @media only screen and (min-height: 1200px) {
      bottom: 181px;
      position: fixed;
     }
  }

  .checkbox {
    display: flex;
    position: relative;
    cursor: pointer;
    align-items: center;
    margin-bottom: 50px;

    input[type="checkbox" i] {
      display: none;
    }

    input {
      cursor: pointer;
      &:checked ~ span {
      background: #ffffff;
      border: none;

      &:after {
        display: block;
      }
    }
    }

    .checkbox-label {
      font-size: 16px;
      display: flex;
      flex: 1 1 auto;
      margin-left: 10px;
     }

    span {
      position: relative;
      height: 24px;
      min-width: 24px;
      border-radius: 3px;
      border: 3px solid #ffffff;
      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 8px;
        top: 3px;
        width: 5px;
        height: 11px;
        border: solid #0A2240;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        box-sizing: content-box;
      }
    }
  }
}

