.delete-account {
  text-align: center;
  font-size: small;
  background-color: #F2F3F5;
  color: #0A2240;

  .da-heading {
    font-weight: bolder;
    color: #ac0000;
    padding: 20px 20px 0;
    font-size: 21px;

  }

  .da-body {
    margin: 0 20px 10px;
    font-size: 17px;
  }

  .da-footer {
    background-color: #0A233F0D;
    margin: 15px;
  }

  a.btn-support-center {
    margin-top: 10px;
    border: 1px solid #0a2240;
  }

  .delete-button-container {
    width: 85%;
    padding-top: 15px;
    margin: auto;

    @media (max-width: 900px) {
      width: 100%;
    }
  }
}

div.modal-header>div.close-modal {
  display: block;
  position: absolute;
  top: 10px;
  right: 20px;

  a.close {
    font-size: 200%;
    background-color: #e6e8eb;
    color: black;
  }
}

.modal-header {
  justify-content: center;
}

.reason-dropdown {
  background: #fff url(../../../assets/svgs/Down_arrow_blue.svg) no-repeat;
  background-position: right 0px top 50%;
  background-size: 50px auto;
  border: 1px solid #0A2240;
}

.account--settings__main {
  min-height: 80vh;
  padding-left: 10px;
  padding-right: 10px;
  height: fit-content;
  margin-bottom: 50px;
}

.close-btn {
  border: 0;
  background: none;
}