@import '../../../styles/variables';

.exist-user-modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        justify-content: center;
      }

      .modal-title {
        color: #0A2240;
        font-size: 30px;
        font-family: $tglb;
      }

      .modal-body {
        font-family: $tglc;
        .primary__button {
          margin-bottom: 21px;
        }
        p {
          margin-bottom: 47px;
          letter-spacing: 0.08px;
        }
        .link-blue,
        .link-blue:hover {
          border: none;
          background: none;
          font-family: $tglb;
          font-size: 17px;
        }
      }
    }
  }
}
