.first-carousel-container {
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 0px;
  margin-right: 0px;
  text-align: left;
}

.watch-header {
  h3{
    @media only screen and (max-width: 490px) {
      font-size: 20px !important;
    }
  }
}
