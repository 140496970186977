@import '../../../styles/variables';

.refer-a-friend {
  width: 500px;
  height: 50vh;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 50px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 50vh;
    margin-top: 0px;
  }

  &__text {
    font-family: $tglc;
    font-size: 26px;
    line-height: 34px;

    @media only screen and (max-width: 768px) {
      font-family: $tglc;
      font-size: 18px;
      line-height: 34px;
    }
  }

  &__subtext {
    margin-top: 30px;
    margin-bottom: 50px;
  }
  &__desc {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 550px;
  }
  .mb-0 {
    margin-bottom: 0px;
  }

  &__copied-message {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 2px;
    font-size: 18px;
    font-family: $tgd1;
  }

  &__textbox {
    display: flex;
    align-items: center;
    background-color: none;
    color: #41aeff;
    padding: 10px;
    border: 2px solid #ffffff;
    border-radius: 4px;
    margin: 0;

    &-text {
      flex-grow: 1;
      margin-right: 10px;
      text-align: center;
    }

    &-icon {
      cursor: pointer;
    }
  }
}

.refer-a-freind__footer {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  background: #364b61 0% 0% no-repeat padding-box;
  padding: 19px 0;
  height: 88px;
  position: absolute;
  right: 0;
  left: 0;
}
