@import '../../../styles/variables';

.subscribe-to-gotham {
  height: 100vh;
  background-color: #111; // dark background
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #2f3b4a; // dark blue header background

    .back-button {
      background: none;
      border: none;
      color: white;
      cursor: pointer;
      font-family: $tglbc;
      font-size: 20px;
      margin-top: 2px;
    }

    .profile-icon {
      font-size: 24px;
      color: white;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    padding: 20px;
    overflow-y: auto;
    gap: 40px;

    .title {
      font-family: $tgd1;
      font-size: 46px;
      font-weight: 700;
      line-height: 56.95px;
      text-align: center;
      margin-bottom: 10px;
    }

    .logo-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 50px;

      .yes-logo,
      .gotham-logo {
        max-width: 200px;
        display: block;
        margin: 0 auto;
      }

      .gotham-logo {
        margin-top: 10px;
      }
    }

    .description {
      font-size: 24px;
      font-weight: 400;
      font-family: $tglc;
      margin-top: 60px;

      .bold-text {
        font-weight: 700;
        font-family: $tglbc;
      }

      .faq-link {
        color: white;
      }
    }

    .download-button {
      background-color: #ffffff;
      color: #0a233f;
      padding: 10px 60px;
      font-size: 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-family: $tglbc;
      text-transform: uppercase;
      align-self: center;
    }

    .download-button:hover {
      background-color: #79a8d1;
    }
  }

  .back-button-image {
    filter: brightness(0) invert(1);
    margin-right: 10px;
  }
}
