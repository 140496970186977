.bmpui-ui-watermark {
  display: none;
}

.bitmovin-player {
  position: relative;
  min-height: 450px;
  width: 100%;
}

.bitmovin-player-container {
  position: relative;

  .learn-more {
    position: absolute;
    bottom: 88px;
    right: 20px;
    z-index: 100;
  }
}