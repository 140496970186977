.maintenance {
  background-color: black;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  background-image: url('../../../assets/images/YES_WatchLive_Collage_Web.png');
  box-shadow: 70px 0 100px 0 white inset, -70px 0 100px 0 white inset;
  background-color: #e4e4e4;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 58.64%;
  position: relative;
  &_title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #0a2240e6;
    padding: 3rem 2.5rem;
    border-radius: 35px;
    h1 {
      transform: scale(1, 1.5);
    }
    p {
      color: #d6d6d6;
    }
  }
}
