.back-text{
  padding: 8px 0;
}
.view-all-mvpd-logo{
  height: 27px;
  cursor: pointer;
  margin-bottom: 12px;

  @media only screen and (max-width: 490px) {
    margin-right: 0.4rem !important;
    height: 20px !important;
    margin-bottom: 0.1rem !important;
  }
}
.category-back-button {
  font-size: 1.2rem;
  cursor: pointer;
  color: #fff;

  &:hover {
    transform: scale(1.1, 1.1);
  }
}
.back-button-text{
  &:hover{
    cursor: pointer;
  }
}
.category-back-arrow{
  padding-top: 7px;
  margin-right: 7px;

}