.modal-dialog {
  text-align: center;

  .modal-content {
    border-radius: 8px;

    .modal-header {
      background-color: #e6e8eb;
      border-bottom: 0;
      padding: 16px 70px;
    }

    .modal-title {
      color: #0a2240;
      font: normal normal bold 30px PT Sans;
    }

    .modal-body {
      color: #0a2240;
      font: normal normal normal 16px PT Sans;
      padding: 25px 70px;

      .btn-primary {
          padding: 10px 70px;
          border-radius: 8px;
      }

      .btn-secondary {
          text-decoration: underline;
          margin-top: 15px;
      }
    }
  }
}

.btnInstructions {
    color: red;
}