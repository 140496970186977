.footer-container {
  // height: 185px;
  width: 100%;
  background-color: rgb(12, 24, 60);;
}

.iframe-footer {
  margin-bottom: -9px;
  bottom: 0;
  position: fixed;
  @media only screen and (min-width: 2000px), (min-height: 1000px) {
    position: fixed ;
    
  }
}
