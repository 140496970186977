// PT Sans
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
// Libre Franklin
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'gothic-display-1';
  src: url('../assets/fonts/Trade-Gothic-Display-1-Base.woff2') format('woff2'),
    url('../assets/fonts/Trade-Gothic-Display-1-Base.woff') format('woff'),
    url('../assets/fonts/Trade-Gothic-Display-1-Base.ttf') format('truetype');
}

@font-face {
  font-family: 'gothic-bdcn20';
  src: url('../assets/fonts/TradeGothicLTStd-BdCn20.woff2') format('woff2'),
    url('../assets/fonts/TradeGothicLTStd-BdCn20.woff') format('woff'),
    url('../assets/fonts/TradeGothicLTStd-BdCn20.otf') format('opentype');
}

@font-face {
  font-family: 'gothic-bold';
  src: url('../assets/fonts/TradeGothicLTStd-Bold.woff2') format('woff2'),
    url('../assets/fonts/TradeGothicLTStd-Bold.woff') format('woff'),
    url('../assets/fonts/TradeGothicLTStd-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'gothic-cn18';
  src: url('../assets/fonts/TradeGothicLTStd-Cn18.woff2') format('woff2'),
    url('../assets/fonts/TradeGothicLTStd-Cn18.woff') format('woff'),
    url('../assets/fonts/TradeGothicLTStd-Cn18.otf') format('opentype');
}

.tgd1-base-76 {
  font-weight: bold;
  font-size: 76px;
  color: $dark-blue;
  text-transform: uppercase;
  font-family: $tgd1;
}

.tgd1-b-base-33 {
  font-weight: bold;
  font-size: 33px;
  color: $white;
  text-transform: uppercase;
  font-family: $tgd1;
}

.tgd1-n-base-33 {
  font-size: 33px;
  color: $white;
  text-transform: uppercase;
  font-family: $tgd1;
}

.tgd1-n-base-26 {
  font-size: 26px;
  color: $white;
  text-transform: uppercase;
  font-family: $tgd1;
}

.tgd1-n-base-20 {
  font-size: 20px;
  color: $white;
  text-transform: uppercase;
  font-family: $tgd1;
}

.tgd1-base-16 {
  font-size: 15px;
  color: $white;
  text-transform: uppercase;
  font-family: $tgd1;
}

.tglc-n-grey-19 {
  color: $grey;
  font-family: $tglc;
  font-size: 19px;
}

.tglc-n-grey-18 {
  color: $white;
  font-family: $tglc;
  font-size: 68px;
}

.tglb-b-db-33 {
  color: $dark-blue;
  font-family: $tglb;
  font-size: 33px;
  font-weight: bold;
}

.tgd1-b-base-24 {
  font-weight: bold;
  font-size: 24px;
  color: $white;
  text-transform: uppercase;
  font-family: $tgd1;
}

.tglbc-b-db-25 {
  color: $dark-blue;
  font-family: $tglbc;
  font-size: 25px;
  font-weight: bold;
}

.tglbc-b-db-19 {
  color: $dark-blue;
  font-family: $tglbc;
  font-size: 22px;
  font-weight: bold;

  @media only screen and (max-width: 768px) {
    color: $dark-blue;
    font-family: $tglbc;
    font-size: 19px;
    font-weight: bold;
  }
}

.tglbc-b-db-22 {
  color: $blue;
  font-family: $tglbc;
  font-size: 22px;
}

.tglbc-b-db-20 {
  color: $dark-blue;
  font-family: $tglbc;
  font-size: 20px;
  font-weight: bold;
}

.tglbc-db-20 {
  color: $dark-blue;
  font-family: $tglbc;
  font-size: 20px;
}

//white
.tglb-27 {
  color: $white;
  font-family: $tglb;
  font-size: 27px;
  text-transform: uppercase;
}

.tglb-13 {
  color: $white;
  font-family: $tglb;
  font-size: 13px;
  text-transform: uppercase;
}

.tglc-32 {
  color: $white;
  font-family: $tglc;
  font-size: 32px;
}

.tglc-24 {
  color: $white;
  font-family: $tglc;
  font-size: 24px;
}

.tglc-16 {
  color: $white;
  font-family: $tglc;
  font-size: 16px;
}

.tglc-11 {
  color: $white;
  font-family: $tglc;
  font-size: 11px;
}

.tglb-b-w-33 {
  color: $white;
  font-family: $tglb;
  font-size: 33px;
  font-weight: bold;
}

//white, "sans"
.pts-n-white-14 {
  font-family: $tgd1;
  color: $white;
  font-size: 14px;
}

.pts-n-16 {
  font-family: $pts;
  color: $white;
  font-size: 16px;
}

//white, bold, 'sans'
.pts-b-white-18 {
  font-weight: bold;
  font-family: $pts;
  color: $white;
  font-size: 18px;
}

.pts-n-white-20 {
  font-family: $tgd1;
  color: $white;
  font-size: 20px;
}

.pts-b-white-30 {
  font-family: $tgd1;
  font-weight: bold;
  color: $white;
  font-size: 30px;
}

//dark blue, normal, "sans"
.pts-n-db-14 {
  font-family: $pts;
  color: $dark-blue;
  font-size: 14px;
}

.pts-n-lb-14 {
  font-family: $pts;
  color: $light-blue;
  font-size: 14px;
}

.pts-n-db-17 {
  font-family: $pts;
  color: $dark-blue;
  font-size: 15px;
  font-weight: bold;
}

.pts-n-db-16 {
  font-family: $pts;
  color: $dark-blue;
  font-size: 16px;
}

.pts-n-db-18 {
  font-family: $pts;
  color: $dark-blue;
  font-size: 18px;
}

.pts-n-db-20 {
  font-family: $pts;
  color: $dark-blue;
  font-size: 20px;
}

//dark blue, bold, "sans"
.pts-b-db-14 {
  font-family: $pts;
  color: $dark-blue;
  font-size: 14px;
  font-weight: bold;
}

.pts-b-db-18 {
  font-weight: bold;
  font-family: $pts;
  color: $dark-blue;
  font-size: 18px;
}

.pts-b-db-30 {
  font-family: $pts;
  font-weight: bold;
  color: $dark-blue;
  font-size: 30px;
}

//grey, bold, 'sans'
.pts-b-grey-18 {
  font-weight: bold;
  font-family: $pts;
  color: $grey;
  font-size: 18px;
}

.pts-b-grey-20 {
  font-weight: bold;
  font-family: $pts;
  color: #84909E;
  font-size: 17px;
}

//grey normal, 'sans'
.pts-n-grey-14 {
  font-family: $pts;
  color: $grey;
  font-size: 14px;
}

.pts-b-17 {
  font-weight: bold;
  font-family: $pts;
  font-size: 17px;
}

.pts-b-20 {
  font-weight: bold;
  color: $dark-grey;
  font-family: $pts;
  font-size: 17px;
}

//link style
.link,
.link:hover {
  font-weight: bold;
  text-decoration: underline;
  color: #0a2240 !important;
  cursor: pointer;
}

.link-blue,
.link-blue:hover {
  text-decoration: underline;
  color: #79a8d1 !important;
  cursor: pointer;
}

.link-normal,
.link-normal:hover {
  text-decoration: underline;
  color: #0a2240 !important;
  cursor: pointer;
}