.full-screen {
  position: relative;
}

.back-button {
  font-size: 1.2rem;
  margin-left: 30px;
  cursor: pointer;
  color: #fff;

  &:hover {
    transform: scale(1.1, 1.1);
  }
}

.next-video {
  margin-left: 20px;
}

.next-video-timer {
  color: #00ace6;
  padding-left: 35px;
}

.up-next {
  background-color: black;
  width: 100%;
  color: white;
  text-align: center;
  padding-top: 58.64%;
  position: absolute;
  z-index: 999;
  height: 100%;
}

.up-next-content {
  width: 100%;
  height: 100%;
  padding: 50px;
  justify-content: space-between;
}

.close-button {
  float: left;
}

.space-left {
  margin-top: 5px;
  align-self: start;
}

.close-icon {
  height: 37px;
  width: 37px;
}

.close-icon:hover {
  cursor: pointer;
}

.up-next-duration {
  font-weight: bold;
  font-size: 20px;
}

.cancel-button {
  background: #0a2240 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 0.49;
  height: 50px;
  margin-top: 10px;
  margin-right: 5px;
  color: #fff;
  width: 50%;
}

.cancel-button:hover {
  cursor: pointer;
}

.play-button {
  background: #0a2240 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
  margin-left: 5px;
  height: 50px;
  margin-top: 10px;
  width: 50%;

  :hover {
    cursor: pointer;
  }
}

.play-button:hover {
  cursor: pointer;
}

.float-layout {
  padding: 5px 5px;
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0;
}

.up-next-card-container {
  overflow: hidden;
  width: 100%;
}

.up-next-card {
  background-color: #0a233f;
  color: #fff;
  min-height: 100%;
  /*replace this it in width: 100%*/
  width: 50%;
  float: right;
  border-radius: 0% !important;
  padding: 20px;
}

.up-next-card-title {
  text-align: center;
  font-weight: bold;
  padding-top: 20px;
}

.up-next-card-desc {
  padding: 10px;
  text-align: left;
}

.up-next-card-height {
  height: 75%;
}

/*add this it*/
.up-next-card-image {
  display: flex;
  height: 100%;
}

/*-------------*/

div.up-next-card-image img {
  width: 50%;
  height: auto;
  object-fit: cover;
}

/* Phone Devices Query */
@media only screen and (max-width: 37.5em) {
  div.up-next-card-image img {
    width: 50%;
    height: auto;
  }

  /*----------------------*/

  .up-next-card {
    width: 100%;
    margin-top: -4px;
  }
}
