@import '../../../styles/variables';

.account {
    background-color: unset !important;
    border: none !important;
    border-style: none !important;

    .account--title {
        padding: 25px;
        margin-bottom: 0;
        background-color: #E6E8EB;
        border-bottom: #E6E8EB;
        color: #0a2240;
        font: normal normal bold 25px PT Sans;
    }

    .account--body {
        background-color: unset;
        border: none;
        border-style: none;

        &__profile {
            padding-bottom: 30px;

            a {
                color: #FFFFFF !important;
                text-decoration: none;
            }
        }
    }

    .account--name,
    .account--email {
        font-family: $tglb;
        color: #FFFFFF;

    }

    .account--email {
        margin-bottom: 30px;
    }
}

.profile--sections {
    height: 45px;
    padding: 20px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid;
    border-bottom-color: #193252;
    font-family: $tglc;
    background-color: #0A2240;
    font-size: 14px;

    img {
        height: 14px;
        width: 10px;
    }

    .text {
        margin-top: 8px;
        font-family: $tglc;
    }

}

.account--settings__main {
    min-height: 80vh;
    height: fit-content;
    margin-bottom: 50px;
    color: #FFFFFF;
}

.account--sections {
    color: #84909e;
    font-family: $tglb;
    margin-bottom: 10px;
    margin-left: 7px;
    font-weight: 900;
}

.account--sections__support {
    color: #84909e;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 7px;
}

.back-arrow {
    cursor: pointer;
}

div.modal-header>div.close-modal {
    display: block;
    position: absolute;
    top: 10px;
    right: 20px;

    a.close {
        font-size: 200%;
        background-color: #e6e8eb;
        color: black;
    }
}

.modal-header {
    justify-content: center;
}

.btn-support-center {
    margin-top: 10px;
    border: 1px solid #0a2240;
}

.profile_img {
    padding: 5px;
}

.img-profile {
    padding-top: 30px !important;
    padding-bottom: 11px;
}