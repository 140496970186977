.easelive-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.bmpui-ui-watermark {
    display: none;
}

/* hide the EaseLive container when casting */
.bmpui-remote-control .easelive-container {
    display: none;
}

/* prevent hidden controls from eating the touch events */
.bmpui-hidden,
.bmpui-ui-uicontainer.bmpui-controls-hidden .bmpui-ui-playbacktoggle-overlay {
    display: none;
    pointer-events: none;
}

/* when EL is hidden, let playback toggle overlay handle the click */
.easelive-container.bmpui-hidden + .bmpui-ui-playbacktoggle-overlay {
    display: block;
    pointer-events: auto;
}

/* make the playback toggle button not cover the whole screen, so Ease Live UI can be clicked */
.easelive-container:not(.bmpui-hidden) + .bmpui-ui-playbacktoggle-overlay .bmpui-ui-hugeplaybacktogglebutton {
    width: 8em;
    height: 8em;
    left: 50%;
    top: 50%;
    margin-left: -4em;
    margin-top: -4em;
}

/* show pause icon in playback toggle */

.easelive-container:not(.bmpui-hidden) + .bmpui-ui-playbacktoggle-overlay .bmpui-ui-hugeplaybacktogglebutton.bmpui-on .bmpui-image {
    visibility: visible;
    animation: none;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2032%2032%22%20style%3D%22%22%20xml%3Aspace%3D%22preserve%22%20height%3D%22100%25%22%20width%3D%22100%25%22%3E%0A%3Cstyle%20type%3D%22text%2Fcss%22%3E%0A%09.st0%7Bfill%3A%23FFFFFF%3B%7D%0A%3C%2Fstyle%3E%0A%3Cg%20id%3D%22Rectangle_2_copy%22%3E%0A%09%3Cg%3E%0A%09%09%3Cpath%20class%3D%22st0%22%20d%3D%22M11.3%2C5.4H8.9c-1.3%2C0-2.4%2C1.1-2.4%2C2.4v16.5c0%2C1.3%2C1.1%2C2.4%2C2.4%2C2.4h2.4c1.3%2C0%2C2.4-1.1%2C2.4-2.4V7.7%0A%09%09%09C13.6%2C6.4%2C12.6%2C5.4%2C11.3%2C5.4z%20M11.3%2C24.3H8.9V7.7h2.4V24.3z%22%2F%3E%0A%09%3C%2Fg%3E%0A%3C%2Fg%3E%0A%3Cg%20id%3D%22Rectangle_2_copy_2%22%3E%0A%09%3Cg%3E%0A%09%09%3Cpath%20class%3D%22st0%22%20d%3D%22M23.1%2C5.4h-2.4c-1.3%2C0-2.4%2C1.1-2.4%2C2.4v16.5c0%2C1.3%2C1.1%2C2.4%2C2.4%2C2.4h2.4c1.3%2C0%2C2.4-1.1%2C2.4-2.4V7.7%0A%09%09%09C25.4%2C6.4%2C24.4%2C5.4%2C23.1%2C5.4z%20M23.1%2C24.3h-2.4V7.7h2.4V24.3z%22%2F%3E%0A%09%3C%2Fg%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E");
    background-size: 4em;
}

.easelive-container:not(.bmpui-hidden) + .bmpui-ui-playbacktoggle-overlay .bmpui-ui-hugeplaybacktogglebutton.bmpui-off .bmpui-image {
    animation: none;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2032%2032%22%20style%3D%22%22%20xml%3Aspace%3D%22preserve%22%20height%3D%22100%25%22%20width%3D%22100%25%22%3E%0A%3Cstyle%20type%3D%22text%2Fcss%22%3E%0A%09.st0%7Bfill%3A%23FFFFFF%3B%7D%0A%3C%2Fstyle%3E%0A%3Cg%20id%3D%22Shape_2%22%3E%0A%09%3Cg%3E%0A%09%09%3Cpath%20class%3D%22st0%22%20d%3D%22M23.3%2C15.2L9.8%2C5.6c-0.3-0.2-0.7-0.3-1-0.1C8.5%2C5.7%2C8.3%2C6%2C8.3%2C6.3v19.3c0%2C0.4%2C0.2%2C0.7%2C0.6%2C0.9%0A%09%09%09c0.1%2C0.1%2C0.3%2C0.1%2C0.4%2C0.1c0.2%2C0%2C0.4-0.1%2C0.6-0.2l13.4-9.7c0.3-0.2%2C0.4-0.5%2C0.4-0.8C23.6%2C15.7%2C23.5%2C15.3%2C23.3%2C15.2z%20M10.3%2C23.6V8.4%0A%09%09%09L21%2C16L10.3%2C23.6z%22%2F%3E%0A%09%3C%2Fg%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E");
    background-size: 4em;
}
