.channel {
  max-width: 400px;
  min-width: 280px;
  background-color: #fbfbfb;
  padding: 9px 10px 1px 17px;
  border-radius: 3px;
  margin-right: 25px;
  display: flex;
  align-items: stretch;
  margin-top: 19px;
  justify-content: space-between;

  @media only screen and (max-width:768px) {
    height: 140px;
  }

  h5 {
    margin-top: 7px;
    margin-bottom: 0px;
    // width: 54%;

    @media only screen and (max-width: 490px) {
      font-size: 19px !important;
      margin-bottom: 5px;
      margin-top: 7px;
    }
  }

  button {
    margin-top: 3px !important;
    height: fit-content;

    @media only screen and (max-width: 490px) {
      font-size: 13px !important;
    }
  }

  p {
    margin-top: 3px !important;
    margin-bottom: 8px !important;

    @media only screen and (max-width: 490px) {
      font-size: 19px !important;
    }
  }

  .channel--live-btn {
    float: right;
  }

  .channel--live-btn:hover {
    cursor: pointer;
  }

  .channel--content {
    padding-top: 10px;
    padding-bottom: 12px;
  }

  .channel--title {
    margin: auto;
  }

  .channel--time {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  .channel--image:hover {
    cursor: pointer;
  }

}

.channel:hover {
  cursor: pointer;
}