.iframe-header {
  margin-bottom: -45px;
  max-height: max-content !important;
  #header {
    overflow: visible;
  }
}

@media only screen and (max-width: 1023px) {
  .nav__bar--container .nav__social--icons {
    display: none !important;
  }
}
