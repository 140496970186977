@import '../../../styles/variables';

.cancel-body {
  .sure-to-cancel {
    text-align: center;
    font-size: 18px;
    font-family: $tglc;
    margin-bottom: 40px;

    span {
      font-family: $tglb;
    }
  }

  .access {
    width: fit-content;
    font-family: $tglb;
    font-size: 19px;
    margin: auto;

    li {
      margin-top: 7px;
      list-style-type: none;
      font-size: 14px;
    }

    .check-mark {
      padding-right: 10px;
    }

    .secondary-list {
      font-size: 14px;
      font-family: $tglc;
      list-style-type: disc;
      padding-left: 50px;

      li {
        list-style-type: disc;
        margin-top: 2px;
      }
    }
  }

  .buttons {
    text-align: center;

    button {
      width: 100%;
      margin-top: 20px !important;
    }

    .secondary__button {
      background-color: unset !important;
    }
  }

  .reason {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}