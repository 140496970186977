@import '../styles/variables';

a,
a:hover {
  text-decoration: none;
}

.or {
  position: relative;
  padding: 34px 0 47px 0;

  &:after {
    content: '';
    position: absolute;
    top: 44%;
    left: 0;
    width: 100%;
    border-top: 1px solid #84909e;
  }

  span {
    color: #767D89;
    position: relative;
    z-index: 1;
    padding: 0 30px;
    background: #0a233f;
  }
}

.page-background {
  background-image: url("/Dark_mode_blue_Background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100%;
  color: #ffffff;
  font-family: $tglb;

  @media only screen and (min-height: 1007px) {
    min-height: 100vh;
  }

  .form-label {
    width: unset;
  }

  .header {
    &.just-stepper {
      grid-template-columns: unset;
    }

    background: #364B61 0% 0% no-repeat padding-box;
    padding: 12px 0;
    display: grid;
    grid-template-areas: "head stepper tail";
    grid-template-columns: 33% 33% 33%;
    height: 88px;
    position: sticky;
    z-index: 1;

    &.with-stepper {
      @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: unset;
      }
    }

    &.long-text {
      @media only screen and (max-width: 768px) {

        button {
          font-size: 20px !important;
        }
      }
    }

    .back-section {
      margin: auto;
      grid-area: head;

      &.with-stepper {
        @media only screen and (max-width: 768px) {
          align-self: flex-start;
          margin: 0 0 0 80px;
        }
      }

      button {
        position: relative;
        font-family: $tglc;
        font-size: 23px;
        letter-spacing: 0px;
        color: #FFFFFF;
        background: #364B61;
        border-style: none;

        &> :first-child {
          position: absolute;
          right: 125%;
          bottom: 9%;
        }

        &:focus {
          outline: none
        }
      }
    }

    .stepper-section {
      margin: 4px auto 0;
      grid-area: stepper;

      @media only screen and (max-width: 768px) {
        align-self: center;
      }

      img {
        @media only screen and (max-width: 350px) {
          width: 100%;
        }
      }

      .stepper-text {
        text-align: center;
        color: #ffffff;
        font-family: $tglb;
        font-size: 15px;
        padding-top: 5px;
      }
    }

    &-title {
      text-align: center;
      color: #FFFFFF;
      font-family: $tglb;
      font-size: 22px;
      letter-spacing: 0px;
      cursor: pointer;
      align-self: center;
    }

    .settings-icon {
      grid-area: tail;
      justify-self: center;
      margin: 0 0 0 100px;
      padding-top: 9px;
    }
  }

  .title {
    background: #223852;
    padding: 35px 0;

    &-wrapper {
      font-family: $tgd1;
      letter-spacing: -0.92px;
      font-size: 46px;
      line-height: 42px;
      width: fit-content;
      margin-inline: auto;

      @media only screen and (max-width: 490px) {
        font-size: 33px;
      }

      &-text {
        text-align: left;
        color: #FFFFFF;
      }

      &-subText {
        text-align: left;
        color: #84909E;
      }

      &-newSubText {
        text-align: left;
        color: #0092FF;
      }
    }

    @media only screen and (max-width: 768px) {
      height: 155px;
    }
  }
}

.background-light {
  background-image: url("/Light mode blue Background.png") !important;
}

.confirmation {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 100VH;
  color: #FFFFFF;
  font-family: $tglb;
  font-size: 22px;
  max-width: 767px;

  &-text {
    text-align: center;
  }

  &-link {
    height: 30%;
    text-align: center;
    text-decoration: underline;
    font-size: 16px;
    margin-top: 40px;
    cursor: pointer;
  }
}

// Yes-Authentication
.auth {
  &__card {
    max-width: 767px;
    margin: 0px auto 0px;

    &-header {

      //border-radius: 6px 6px 0px 0px;
      //background-color: #edeff0;
      // border-bottom: 1px solid #cecbcb;
      //padding: 30px 20px 0px 20px;
      h2 {
        @include media-breakpoint-down(md) {
          font-size: 2rem;
        }
      }

      @include media-breakpoint-up(md) {
        padding: 30px 100px 0px 100px;
      }

      .steps {
        margin-bottom: 5px;
        @extend .pts-n-db-16;
        text-transform: uppercase;
      }

      .tabs {
        background-color: #dee2e0;
        display: flex;
        max-width: fit-content;

        @include media-breakpoint-down(md) {
          margin: auto;
        }

        .tab {
          text-align: center;
          padding: 15px 0px;
          width: 164px;
          cursor: pointer;
          border: 5px solid transparent;

          h5 {
            text-transform: uppercase;
            margin-bottom: 0px;
            @extend .pts-b-grey-18;

            &:hover {
              text-decoration: none;
            }
          }

          &--active {
            background-color: #f3f4f6;
            border-top: 5px solid $dark-blue;
            margin-bottom: -1px;
            border-left: 1px solid #cecbcb;
            border-right: 1px solid #cecbcb;

            h5 {
              color: $dark-blue;
            }
          }
        }
      }
    }

    &-body {

      // background: transparent linear-gradient(180deg, #f2f3f5 0%, #ffffff 100%) 0% 0%
      //   no-repeat padding-box;
      @media only screen and (max-width: 590px) {
        padding: 88px 20px;
      }

      padding: 35px 100px 88px 100px;
    }
  }
}

.text-separator {
  @extend .pts-b-grey-18;
  text-transform: uppercase;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  text-align: center;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #c1c2ce;
  }

  &::before {
    margin-right: 1em;
  }

  &::after {
    margin-left: 1em;
  }
}

// Yes Background

.auth-background {
  background-image: url('../assets/images/background_left_main.png'), // left
    url('../assets/images/background_right_main.png'), // right
    url('../assets/images/background_left_grayscale.png'), // left fade
    url('../assets/images/background_right_grayscale.png'), // right fade
    url('../assets/images/CITY.png'),
    url('../assets/images/grey-bg.png');
  background-repeat: no-repeat;
  background-position: 20% 56px,
    right 20% top 56px,
    left 6% top 168px, // left fade
    right 0% top 128px, // right fade
    0 1px,
    0 0;
  background-size: 259px 678px,
    contain,
    182px 353px, // left fade
    288px 427px, // right fade
    100% 199%, // city
    cover,
    253px 521px,
    contain,
    262px 468px;
  height: 436px;

  @media only screen and (max-width: 1110px) {
    background-image: url('../assets/images/grey-bg.png');
    background-repeat: no-repeat;
    background-position: 0% 0%;
    background-size: 100% 100%;
    height: 436px;
  }

  @include media-breakpoint-down(md) {
    background-image: url('../assets/images/Durant.png'),
      url('../assets/images/Aaron_Judge_1.png'), url('../assets/images/CITY.png'),
      url('../assets/images/grey-bg.png');
    background-repeat: no-repeat;
    background-position: 1% 32px, right 3% top 22px, 0 -58px, 0 0;
    background-size: 133px 294px, contain, 100% 121%, cover;
    height: 247px;
  }

  @media only screen and (max-width: 494px) {
    background-image: url('../assets/images/grey-bg.png');
    background-repeat: no-repeat;
    background-position: 0% 0px;
    background-size: 100% 100%;
    height: 247px;
  }
}

.app-background {
  background-image: url('../assets/images/Cole.png'),
    url('../assets/images/KyrieIrving.png'), url('../assets/images/Cole_Action.png'),
    url('../assets/images/Action.png'), url('../assets/images/Empire_State_Building.png'),
    linear-gradient(270deg, #e3e3e3 100%, #ffffff00 0%, #e3e3e3 100%);
  background-repeat: no-repeat;
  background-position: 18% 56px, right 18% top 47px, 7% 165px, right 9% top 150px,
    0px -151px, 0 0;
  background-size: 259px 678px, 270px 437px, 317px 556px, 187px 353px, 100% 199%, cover,
    253px 521px, contain, 262px 476px;
  height: 436px;

  @include media-breakpoint-down(md) {
    background-image: url('../assets/images/Cole.png'),
      url('../assets/images/KyrieIrving.png'),
      url('../assets/images/Empire_State_Building.png'),
      linear-gradient(270deg, #e3e3e3 100%, #ffffff00 0%, #e3e3e3 100%);
    background-repeat: no-repeat;
    background-position: 1% 24px, right 1% top 22px, 0 -58px, 0 0;
    background-size: 150px 283px, 194px 283px, 100% 121%, cover;
    height: 247px;
  }
}

.favorite-player-background {
  background-image: url('../assets/images/Cole.png'),
    url('../assets/images/Basketball_Player.png'), url('../assets/images/Aaron_Judge_1.png'),
    url('../assets/images/KyrieIrving.png'), url('../assets/images/CITY.png'),
    linear-gradient(270deg, #e3e3e3 100%, #ffffff00 0%, #e3e3e3 100%);
  background-repeat: no-repeat;
  background-position: 18% 56px, right 18% top 47px, 7% 70px, right 9% top 68px, 0px -151px, 0 0;
  background-size: 259px 678px, 434px 633px, 246px 462px, 270px 437px, 100% 199%, cover, 253px 521px, contain, 262px 476px;
  height: 436px;

  @include media-breakpoint-down(md) {
    background-image: url('../assets/images/Cole.png'),
      url('../assets/images/KyrieIrving.png'),
      url('../assets/images/Empire_State_Building.png'),
      linear-gradient(270deg, #e3e3e3 100%, #ffffff00 0%, #e3e3e3 100%);
    background-repeat: no-repeat;
    background-position: 1% 24px, right 1% top 22px, 0 -58px, 0 0;
    background-size: 150px 283px, 194px 283px, 100% 121%, cover;
    height: 247px;
  }
}

.yes-background {
  &__header {
    width: 55%;
    margin: auto;
    text-align: center;
    max-width: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include media-breakpoint-down(md) {
      height: 247px;
    }

    @include media-breakpoint-up(md) {
      padding: 32px 0px;
    }

    @include media-breakpoint-down(xs) {
      width: 90%;
    }

    .logo {
      img {
        @include media-breakpoint-down(md) {
          width: 40px;
          height: 40px;
        }
      }
    }

    h1 {
      margin-top: 10px;

      @include media-breakpoint-down(md) {
        font-size: 2rem;
      }
    }

    .description {
      // font-family: 'trade gothic';
      color: $light-grey-1;
      padding: 5px 10px;
      font-size: 19px;
      font-family: $tglb;

      span {
        background-color: $dark-blue;
        padding: 10px 20px;
        display: block;
        font-family: $pts;
        font-weight: 550;
        margin: 0px auto 10px;
        width: fit-content;
      }

      b {
        color: $white;
      }

      @include media-breakpoint-down(md) {
        font-size: 0.7rem;
        margin-bottom: 5px;
      }
    }

    p {
      color: $dark-blue;
      font-size: 14px;
      font-family: $tglb;

      @include media-breakpoint-down(md) {
        font-size: 0.7rem;
        width: 64%;
      }
    }
  }
}

.yes-card {
  font-family: $pts;
  max-width: 767px;
  margin: -115px auto 100px;

  @include media-breakpoint-down(md) {
    margin: 0 auto 150px;
  }

  &__header {
    border-radius: 6px 6px 0px 0px;
    background-color: #edeff0;
    border-bottom: 1px solid #cecbcb;
    padding: 30px 20px 0px 20px;

    h2 {
      @include media-breakpoint-down(md) {
        font-size: 2rem;
        text-align: center;
      }
    }

    @include media-breakpoint-up(md) {
      padding: 42px 100px 0px 100px;
    }

    .hint {
      margin-bottom: 5px;
      @extend .pts-n-db-16;
      text-transform: uppercase;
    }

    .sub-title {
      margin-bottom: 12px;
      min-height: 24px;
      @extend .pts-n-db-16;

      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }

    .tabs {
      background-color: #dee2e0;
      display: flex;
      width: fit-content;

      @include media-breakpoint-down(md) {
        margin: auto;
      }

      .tab {
        text-align: center;
        padding: 15px 0px;
        width: 164px;
        cursor: pointer;
        border: 5px solid transparent;

        h5 {
          text-transform: uppercase;
          margin-bottom: 0px;
          @extend .pts-b-grey-18;

          &:hover {
            text-decoration: none;
          }
        }

        &--active {
          background-color: #f3f4f6;
          border-top: 5px solid $dark-blue;
          margin-bottom: -1px;
          border-left: 1px solid #cecbcb;
          border-right: 1px solid #cecbcb;

          h5 {
            color: $dark-blue;
          }
        }
      }
    }
  }

  &__body {
    background: transparent linear-gradient(180deg, #f2f3f5 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
    padding: 40px 20px;

    @include media-breakpoint-up(md) {
      padding: 40px 100px;
    }

    min-height: 544px;
    margin-bottom: 400px;
  }
}

.yes-card-reset-component {
  font-family: $pts;
  max-width: 767px;
  margin: -115px auto 100px;

  @include media-breakpoint-down(md) {
    margin: 0 auto 150px;
  }

  &__header {
    border-radius: 6px 6px 0px 0px;
    background-color: #061627;
    border-bottom: 1px solid #cecbcb;
    border-width: medium;
    padding: 30px 20px 0px 20px;

    h2 {
      @include media-breakpoint-down(md) {
        font-size: 2rem;
        text-align: center;
      }
    }

    @include media-breakpoint-up(md) {
      padding: 42px 100px 0px 100px;
    }

    .hint {
      margin-bottom: 5px;
      @extend .pts-n-db-16;
      text-transform: uppercase;
    }

    .sub-title {
      margin-bottom: 12px;
      min-height: 24px;
      @extend .pts-n-db-16;

      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }

    .tabs {
      background-color: #dee2e0;
      display: flex;
      width: fit-content;

      @include media-breakpoint-down(md) {
        margin: auto;
      }

      .tab {
        text-align: center;
        padding: 15px 0px;
        width: 164px;
        cursor: pointer;
        border: 5px solid transparent;

        h5 {
          text-transform: uppercase;
          margin-bottom: 0px;
          @extend .pts-b-grey-18;

          &:hover {
            text-decoration: none;
          }
        }

        &--active {
          background-color: #f3f4f6;
          border-top: 5px solid $dark-blue;
          margin-bottom: -1px;
          border-left: 1px solid #cecbcb;
          border-right: 1px solid #cecbcb;

          h5 {
            color: $dark-blue;
          }
        }
      }
    }
  }

  &__body {
    // background: transparent linear-gradient(180deg, #f2f3f5 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
    padding: 40px 20px;
    background: #061627;


    @include media-breakpoint-up(md) {
      padding: 40px 100px;
    }

    min-height: 544px;
    margin-bottom: 400px;
  }
}

.full-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;

  .spinner-border {
    height: 60px;
    width: 60px;
  }
}