@import '../../../styles/variables';
.activationCode{
    height: 100vh;
    padding-top: 200px;

    h2{
        padding-bottom: 50px;
        font-size: 30px;
        width: 80%;
        font-family: $tglbc;
        margin: auto;
    }
}