
.change-your-plan{
    text-align: center;
    button{
        width: 100%;
        margin-top: 90px;
        margin-bottom: 30px;
    }
    .link-blue{
        color: #FFFFFF !important;
    }
}