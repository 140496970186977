.playlistcard {
  height: max-content;
  margin: 0px 0px;
  padding: 5px;
  background-color: unset !important;
  border: none !important;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.3) !important;
    border-radius: 7px;
  }

  .cardbody {
    padding: 5px;
    //padding-bottom: 15px;
  }
  .cardimage-a {
    height: 0;
    overflow: hidden;
    padding-top: 1/1 * 100%;
    border-radius: 7px;
    position: relative;
  }
  .cardimage-b {
    height: 0;
    overflow: hidden;
    padding-top: 4/3.3 * 100%;
    border-radius: 7px;
    position: relative;
  }
  .card_image {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 7px;
    width: 100%;
    height: 100%;
    // z-index: 1;
  }
  .cardtitle-a {
    text-align: left;
    margin-left: 0px;
    padding: 0px;
    padding-top: 5px;
    min-height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .cardtitle-b {
    text-align: left;
    margin-left: 0px;
    padding: 0px;
    padding-top: 5px;
    min-height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .publishdate {
    padding: 5px;
  }
  .vidlen {
    background-color: #0a233f;
    padding: 3px 12px;
    border-radius: 4px;
    height: 25px;
    text-align: center;
    width: fit-content;
  }
}

.title-tooltip > .tooltip-inner {
  background-color: #0a233f;
  color: #fff;
  border: none;
}
