.form-group {
  font-family: $tglb;

  .form-label {
    top: 377px;
    left: 675px;
    width: 37px;
    height: 18px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0px;
    color: #FFFFFF;

    &.is-invalid {
      background-color: unset;
      color: #EF5A4C;
    }

    &.color-dark {
      color: #000000;
    }

    span {
      color: $dark-red;
      background-color: unset;
    }
  }

  span {
    color: #a9a9a9;
  }

  margin-bottom: 1.5rem;
}

.mb-55 {
  margin-bottom: 55px;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none !important;
  -webkit-appearance: none !important;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none !important;
  appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield !important;
  ;
  /* Firefox */
}


input.form-control {
  border: 1px solid #FFFFFF4D;
  border-radius: 5px;
  background-color: unset;
  color: #FFFFFF;

  &:hover {
    border: 1px solid #a9a9a9
  }

  &:focus {
    background-color: unset;
    box-shadow: unset !important;
    color: #FFFFFF;
  }

  &.is-valid {
    background-image: url('../assets/svgs/check_mark.svg');
  }
}

// input.IOS{
//   padding-top: 2px;
// }

.invalid-feedback {
  background-color: unset;
  color: $dark-red;
  font-size: 17px;
  font-family: $tglc;
  border-radius: 5px;
  font-weight: bold;
}

.hint-msg {
  color: #868686;
  font-family: $lf;
  font-size: 12px;
  font-weight: 200;
  margin-bottom: 0.3rem;
  background-color: unset;
}

.italic-placeholder::placeholder {
  font-style: italic;
  background-color: unset;
}

.c-pointer {
  cursor: pointer;
  background-color: unset;
}

.height-fit-content {
  height: fit-content;
  background-color: unset;
}