@import '../../styles/variables';

.error-screen {
  &.video-mode {
    overflow: hidden;
    background: unset;
    color: white;
  }

  background-image: url('../../assets/images/YES WatchLive_Collage_Web_err.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0A2240;
  text-align: center;
  padding-top: 58.64%;
  position: relative;

  @media only screen and (max-width: 490px) {
    background-color: black !important;
    background-image: none;
  }

  #error-screen-video {
    object-fit: unset;
    width: inherit;
    top: 0;
    position: absolute;
    right: 0;
    min-width: 100%;
    min-height: 100%;
  }

  .title-width {
    width: 363px;
    margin-left: 35px;

    @media only screen and (max-width: 490px) {
      width: 200px !important;
      margin-left: unset;
      display: inline-block;
    }
  }

  h3 {
    @media only screen and (max-width: 490px) {
      font-size: 25px !important;
      color: #F8F8F8;
    }
  }

  p {
    width: 464px;
    text-align: center;
    font-size: 24px;
    font-family: $tglb;
    letter-spacing: 0.48px;

    @media only screen and (max-width: 490px) {
      font-size: 15px !important;
      color: #F8F8F8;
      display: table-cell;
    }
  }

  //button{
  //  @media only screen and (max-width: 872px) {
  //    display: none !important;
  //  }
  //}
  &_title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .error-screen-subscribe-content {
    text-align: center;
    font: 'gothic-bdcn20';
    letter-spacing: 0.48px;
    font: 24px;
    color: #FFFFFF;
    opacity: 1;
  }

}

.error-screen-vodlist {
  background-color: black;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0A2240;
  text-align: center;
  background-image: url('../../assets/images/white_gradient_bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 58.64%;
  position: relative;

  .title-width {
    width: 200px;
    margin-left: 83px;

    @media only screen and (max-width: 490px) {
      width: min-content !important;
      margin-left: 31%;
    }

    @media only screen and (max-width: 340px) {
      width: min-content !important;
      margin-left: 31%;
      padding-top: 7%;
    }
  }

  h3 {
    @media only screen and (max-width: 490px) {
      font-size: 25px !important;
    }
  }

  p {
    width: 360px;
    text-align: center;
    font-size: 20px;
    font-family: $tglb;
    letter-spacing: -0.2px;

    @media only screen and (max-width: 490px) {
      width: fit-content;
      font-size: 15px !important;
    }
  }

  &_title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}