@import '../../../styles/variables';

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 32px;
    border-radius: 8px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    border-radius: 8px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 29px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 6px;
}

input:checked+.slider {
    background-color: #0B183D;
    right: -2px;
}

input:focus+.slider {
    box-shadow: 0 0 1px #0B183D;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.notication-items {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    margin-right: 6px;
    width: 100%;
    .item{
        display: grid;
        justify-content: center;
        align-items: center;
    }
}

.notification-text {
    padding: 10px;
    // width: 64%;
    height: 60px;
    color: #FFFFFF;
    margin-bottom: 60px;
}

.notification-title {
    text-align: center;
    color: #FFFFFF;
    font-family: $tgd1;
    font-size: 44px;
    margin-top: 70px;
    margin-bottom: 30px;
}

.notification-body {
    background-color: #0A2240;
    padding: 35px 30px 35px 30px;
    border-radius: 7px;
}

.disabled-text {
    color: #FFFFFF;
    padding-right: 50px;
}

.disabled-item { 
    background-color: rgba(255, 255, 255, 0.5);
}
.short-item{
    margin-right: 68px !important;
}

.disabled-slider:before{
    position: absolute;
    content: "";
    height: 25px;
    width: 29px;
    left: 3px;
    bottom: 3px;
    background-color: #bdb8b8 !important;
    transition: .4s;
    border-radius: 6px;
}

.notification-container {
    @media only screen and (min-height: 1000px) {
        height: 100vh;
    }
}