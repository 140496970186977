.modal-dialog {
  text-align: center;

  @media only screen and (max-width: 320px) {
    width: 330px;
  }

  .modal-content {
    border-radius: 8px;

    .modal-header {
      border-bottom: 0;
      display: flex !important;
      justify-content: center;
      padding-bottom: 0px;
      @media only screen and (max-width: 768px) {
        padding: 15px;
       }
    }



    .modal-title {
      color: #0a2240;
      font: normal normal bold 15px PT Sans !important;
      text-align: left;
      @media only screen and (max-width: 768px) {
       text-align: left;
      }
    }

    .modal-body {
      color: #0a2240;
      font: normal normal normal 16px PT Sans;
      padding: 25px 70px;
      @media only screen and (max-width: 768px) {
       padding: 15px 20px;
      }
      @media only screen and (max-width: 991px) {
        padding: 15px 12px;
       }

      .content-header{
        background-color: #0a2240;
        color: #ffffff;
        text-align: left ;
        padding: 5px ;
      };

      .content-body-upper {
        padding: 10px;
        display: flex;
        border: #000 1px solid;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width: 768px) {
          display: block;
        }
      }

      .content-body-below-mainpart {
        border: #000 1px solid;  
      }

      .content-body-below-subpart {
        padding: 10px;
        display: flex;
        justify-content: space-between;  
        border-bottom:  #000 1px solid;
        align-items: center;
        @media only screen and (max-width: 768px) {
          display: block;
        }
      }

      .content-body-below-subpart-withoutbottomborder {
        padding: 10px;
        display: flex;
        justify-content: space-between; 
        align-items: center;
        @media only screen and (max-width: 768px) {
          display: block;
        }
      }

      .content-body-below-subpart-title {
        text-align: left;
        width: 400px;

        @media only screen and (max-width: 768px) {
          width: 450px
        }

        @media only screen and (max-width: 420px) {
          width: 350px;
          text-align: center;
        }
        @media only screen and (max-width: 392px) {
          width: 300px;
          text-align: center;
        }
      }

      .content-body-below-subpart-dismiss-btn{
        margin-left: 10px;
      }
    }
  }
}
