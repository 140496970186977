@import '../../../styles/variables';

.subscriber-page {
  background-image: unset !important;

  .subscribe-page {
    color: #ffffff;
    font-family: $tgd1;
    min-height: 100%;
    position: relative;
    flex: 1;
    @media only screen and (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #landingPageVideo {
      object-fit: cover;
      width: 100vw;
      top: 0;
      position: absolute;
      right: 0;
      min-width: 100%;
      min-height: 100%;
      z-index: -2;
    }

    .darkMode {
      display: none;
      @media only screen and (max-width: 768px) {
        display: unset;
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        position: fixed;
        right: 0;
        min-width: 100%;
        min-height: 100%;
        z-index: -1;
      }
    }

    .subscribe-content {
      max-width: 475px;
      text-align: center;
      padding-top: 220px;
      padding-bottom: 263px;
      @media only screen and (min-width: 768px) {
        margin-left: 146px;
      }

      .subscribe-title {
        font-size: 42px;
        line-height: 50px;
        letter-spacing: 0.84px;
        margin-bottom: 60px;
      }

      .team-icons {
        @media only screen and (max-width: 768px) {
          max-width: 350px;
        }
      }

      .subscribe-desc {
        font-size: 29px;
        font-family: $tglb;
        letter-spacing: 0.58px;
        margin-top: 80px;
      }

      .subscribe-sub-desc {
        font-size: 24px;
        font-family: $tglc;
        letter-spacing: 0.48px;
        margin-bottom: 28px;
      }
    }
  }
}
