.team-select {
  display: flex;
  justify-content: center;
  min-height: fit-content;

  @media only screen and (max-width: 768px) {
    display: contents;
    margin: -49px -34px 50px;
  }
}

.NewYork-Yankees {
  background: transparent url('/Yankees_Default.png') 0% 0% no-repeat padding-box;
  height: 196px;
  padding: 5px;
  text-align: center;
  border: none !important;
  display: block;
  outline: none !important;
  background-position: center;
  background-size: contain;
  width: 100%;
  margin-bottom: 10px;

  @media only screen and (max-width: 290px) {
    height: 130px;
  }

  &:hover {
    background: transparent url('/Yankees_Hover.png') 0% 0% no-repeat padding-box;
    width: 100%;
    // height: 20vh;
    background-size: contain;
    background-position: center;
    background-size: contain;
  }

  &.YankeesSelected {
    background: transparent url('/Yankees_Selected.png') 0% 0% no-repeat padding-box;
    width: 100%;
    background-size: contain;
    outline: none;
    background-position: center;
    background-size: contain;
  }
}

.Brooklyn-Nets {
  background: transparent url('/Nets_Default.png') 0% 0% no-repeat padding-box;
  height: 196px;
  padding: 5px;
  text-align: center;
  border: none !important;
  display: block;
  outline: none !important;
  background-position: center;
  background-size: contain;
  width: 100%;
  margin-bottom: 10px;

  @media only screen and (max-width: 290px) {
    height: 130px;
  }

  &:hover {
    background: transparent url('/Nets_Hover.png') 0% 0% no-repeat padding-box;
    width: 100%;
    // height: 20vh;
    background-size: contain;
    background-position: center;
    background-size: contain;
  }

  &.NetsSelected {
    background: transparent url('/Nets_Selected.png') 0% 0% no-repeat padding-box;
    width: 100%;
    background-size: contain;
    outline: none;
    background-position: center;
    background-size: contain;
  }
}

.NewYork-Liberty {
  background: transparent url('/Liberty_Default.png') 0% 0% no-repeat padding-box;
  height: 196px;
  padding: 5px;
  text-align: center;
  border: none !important;
  display: block;
  outline: none !important;
  background-position: center;
  background-size: contain;
  width: 100%;
  margin-bottom: 10px;

  @media only screen and (max-width: 290px) {
    height: 130px;
  }

  &:hover {
    background: transparent url('/Liberty_Hover.png') 0% 0% no-repeat padding-box;
    width: 100%;
    background-size: contain;
    background-position: center;
    background-size: contain;
  }

  &.LibertySelected {
    background: transparent url('/Liberty_Selected.png') 0% 0% no-repeat padding-box;
    width: 100%;
    background-size: contain;
    outline: none;
    background-position: center;
    background-size: contain;
  }
}

.team-select:hover {
  margin-bottom: -10px;
}

button:disabled {
  background-color: #9da6b2 !important;
  border-color: #9da6b2 !important;
}

.update-button {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  background: #364B61 0% 0% no-repeat padding-box;
  padding: 19px 0;
  height: 88px;
  position: absolute;
  right: 0;
  left: 0;
  button {
    @media only screen and (max-width: 400px) {
      width: 100%;
    }
  }
}

.team-picker {
  align-items: center;
}

.team-picker-settings {
  width: 100%;
  padding: 0px 10px 50px 10px;
}
