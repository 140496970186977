@import '../../../styles/variables';

.all {
    color: #FFFFFF;
    text-align: center;
    padding: 35px 100px 88px 100px;
    // padding-top: 50px;
    flex-direction: column;
    width: fit-content;
    margin-bottom: 10px;

    @media only screen and (max-width: 420px) {
        padding: 35px 0px 88px 0px;
    }
}

.message {
    font-size: 30px;
    padding-bottom: 45px;
}

.location {
    padding-top: 20px;
    padding-bottom: 15px;
    width: 32px;
}

.second-message {
    font-size: 16px;
    letter-spacing: 0.08px;
    margin-bottom: 30px;
    text-align: center;
    font-family: $tglc;
}

.more {
    padding-top: 30px;
    margin-bottom: 15px;
    font-size: $tglbc;
}
