.watch-page {
  min-height: 100vh;
  background-color: #0a2240;
  padding: 22px 0px;
  z-index: 1;

  .player-container {
    max-width: 1293px;
    margin: auto;
    padding: 16px 32px 32px;
    border-radius: 15px;
    background-color: #2f4661;

    .watch-header {
      height: 60px;

      h3 {
        @media only screen and (max-width: 490px) {
          font-size: 20px !important;
          padding-top: 8px !important;
        }
      }

      p {
        overflow: visible !important;
        white-space: nowrap !important;

        @media only screen and (max-width: 490px) {
          font-size: 15px !important;
          padding-top: 6px;
          overflow: visible !important;
          white-space: nowrap !important;
        }
      }
    }

    .watch-description {
      background-color: #fbfbfb;
      padding: 20px 20px;

      p {
        margin-bottom: 8px;
      }

      h5 {
        @media only screen and (max-width: 490px) {
          font-size: 19px !important;
        }
      }

      .time-moment {
        @media only screen and (max-width: 490px) {
          font-size: 19px !important;
        }
      }
    }

    .error-channel {
      height: 120px;
    }

    .mvpd-logo {
      height: 27px;
      cursor: pointer;

      @media only screen and (max-width: 490px) {
        margin-right: 0.4rem !important;
        height: 20px !important;
        margin-bottom: 0.1rem !important;
      }
    }
  }

  .overflow-list {
    height: 550px;
    width: inherit;
    max-width: 400px;
    position: absolute;
    overflow-y: scroll;

    @media only screen and (max-width: 768px) {
      height: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      overflow-y: hidden;
      overflow-x: scroll;
    }
  }

  .overflow-parent {
    min-height: 200px;
  }

  .overflow-flex {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    flex: 1;
  }

  .disable {
    display: none;
  }

  .left-arrow-button {
    position: absolute;
    top: 30%;
    left: 10px;
    width: 50px;
    height: 50px;
    background-color: rgba(112, 112, 112, 0.19);
    border-radius: 50%;
    border: none;
    align-content: center;
    justify-content: center;
    display: flex;

    &:hover {
      position: absolute;
      top: 30%;
      left: 10px;
      width: 50px;
      height: 50px;
      background-color: rgba(255, 255, 255, 0.45);
      border-radius: 50%;
      border: none;
      align-content: center;
      justify-content: center;
      display: flex;
    }

    &:focus {
      box-shadow: none !important;
    }

    .left-arrow {
      width: 25px;
      height: 45px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .right-arrow-button {
    position: absolute;
    top: 30%;
    right: 10px;
    width: 50px;
    height: 50px;
    background-color: rgba(112, 112, 112, 0.19);
    border-radius: 50%;
    border: none;
    align-content: center;
    justify-content: center;
    display: flex;

    &:hover {
      position: absolute;
      top: 30%;
      right: 10px;
      width: 50px;
      height: 50px;
      background-color: rgba(255, 255, 255, 0.45);
      border-radius: 50%;
      border: none;
      align-content: center;
      justify-content: center;
      display: flex;
    }

    &:focus {
      box-shadow: none !important;
    }

    .right-arrow {
      width: 25px;
      height: 45px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .playlist-container {
    max-width: 1293px;
    margin: auto;
    padding: 16px 32px 32px;
    border-radius: 15px;
    background-color: #2f4661;
    //background-image: url("/light blue texture BG.png");
    //background-repeat: no-repeat;
    //background-size: 100% 100%;

    .video-container {
      padding: 20px 0px;
    }
  }

  .search-container {
    width: 100%;
    max-width: 1293px;
    margin: auto;
    padding: 0px 0px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;

    @media only screen and (max-width: 570px) {
      flex-direction: column;
    }
  }

  .search-text {
    width: 100% !important;
    height: 36px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    flex: none;
  }

  .cancel-search {
    text-align: right;
    letter-spacing: -0.41px;
    color: #ffffff;
    opacity: 1;
    margin-left: 14px;
    margin-bottom: 1.5rem;
  }

  .cancel-search:hover {
    cursor: pointer;
  }

  .has-search .form-control {
    padding-left: 2.375rem;
    width: 488px;
    height: 36px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;
    color: #495057;

    @media only screen and (max-width: 570px) {
      width: 290px;
    }
  }

  .has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
  }

  .first-carousel-container {
    margin-bottom: 20px;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    text-align: left;
  }
}

.first-carousel-container {
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 0px;
  margin-right: 0px;
  text-align: left;
}

.settings-icon {
  color: #fff;
  margin: 0px 10px 22px 20px;
  cursor: pointer;
}

.search-section {
  max-width: 1293px;
  margin: auto;
}