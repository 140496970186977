@import '../../../styles/variables';

.mvpd-select-page {
  min-height: 100vh;
}

.no-provider-link {
  font-size: 13px;
  font-family: $tglc;
  text-align: center !important;
  margin-top: 27px;

  .link-blue {
    font-weight: unset !important;
  }
}

.service-provider-list {
  font-size: 18px !important;
  border: 1px solid #FFFFFF4D !important;
  border-radius: 5px !important;
  background: url("/drop down.svg") no-repeat right 19px top 50% !important;
  background-size: 17px 9px !important;
}

.suggested-mvpds {
  background: transparent linear-gradient(180deg, #f2f3f5 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-bottom: 28px;

  .col-md-4 {
    border: 1px solid #D0D0D0;
    height: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 80%;
      @media only screen and (max-width: 576px) {
        width: 50% !important;
      }
    }
  }

  position: relative;

  .border-none {
    border: 0.5px solid $dark-blue;
    position: absolute;
  }

  .left-border {
    left: 0;
    width: 0;
    height: 100%;
    padding: unset;
  }

  .right-border {
    right: 0;
    width: 0;
    height: 100%;
    padding: unset;
  }

  .top-border {
    left: 0;
    width: 100%;
    height: 0;
  }

  .bottom-border {
    bottom: 0;
    width: 100%;
    height: 0;
    background-color: #f9f9fa;
  }
}

.optimum {
  width: 156px;
  height: 45px;
}

.spectrum {
  width: 140px;
  height: 32px;
}

.fios {
  width: 87px;
  height: 66px;
}

.xfinity {
  width: 131px;
  height: 47px;
}

.directTV {
  width: 167px;
  height: 49px;
}
